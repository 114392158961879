/** Vendors **/
import React from "react";
import { Box } from "@chakra-ui/react";

function Copyright(): JSX.Element {
  return (
    <Box className="footer-copyright">
      © 2022{" "}
      <a
        href="https://blueumbrella.consulting"
        referrerPolicy="strict-origin"
        rel="noreferrer"
        target="_blank"
      >
        Blue Umbrella LLC
      </a>
      . All Rights Reserved.
    </Box>
  );
}
export default Copyright;
