function Logo() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 16V32"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M54 47L48 50L42 47V36H54V47Z"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M48 10H62V62H2V10H16"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M48 2L32 16L48 26V2ZM48 2H16V26L32 16L16 2"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
export default Logo;
