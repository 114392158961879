import React from "react";

/** Custom Components**/
import Hero from "../cards/Hero";
import Process from "../cards/Process";
import SchemaHeader from "../../shared/schema/Header";
import Steps from "../../shared/steps/Steps";

/** Cusotm CSS **/
import "../../learnmore/home.css";

function Home(): React.ReactElement {
  return (
    <React.Fragment>
      <SchemaHeader details={{}} page="home" />
      <Hero />
      <Process />
      <Steps />
    </React.Fragment>
  );
}

export default Home;
