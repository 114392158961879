function Logo() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 56V49.9C8.99933 49.2052 9.17962 48.5223 9.52312 47.9184C9.86662 47.3145 10.3615 46.8105 10.959 46.456C12.86 45.328 16.309 44 22 44C27.691 44 31.14 45.328 33.041 46.454C33.6388 46.8087 34.1339 47.313 34.4774 47.9173C34.8209 48.5215 35.001 49.2049 35 49.9V56"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M15 31C15 29.1435 15.7375 27.363 17.0503 26.0503C18.363 24.7375 20.1435 24 22 24C23.8565 24 25.637 24.7375 26.9497 26.0503C28.2625 27.363 29 29.1435 29 31C29 34.866 25.866 39 22 39C18.134 39 15 34.866 15 31Z"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M28 14H2V56H56V30"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M34 7V19C34 19.6566 34.1293 20.3068 34.3806 20.9134C34.6319 21.52 35.0002 22.0712 35.4645 22.5355C36.4021 23.4732 37.6739 24 39 24H40V30L50 24H57C57.6566 24 58.3068 23.8707 58.9134 23.6194C59.52 23.3681 60.0712 22.9998 60.5355 22.5355C60.9998 22.0712 61.3681 21.52 61.6194 20.9134C61.8707 20.3068 62 19.6566 62 19V7C62 5.67392 61.4732 4.40215 60.5355 3.46447C59.5979 2.52678 58.3261 2 57 2H39C37.6739 2 36.4021 2.52678 35.4645 3.46447C34.5268 4.40215 34 5.67392 34 7V7Z"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
export default Logo;
