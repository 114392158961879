/** Vendors */
import { Box, Link } from "@chakra-ui/react";

/** Customer Components */
import Logo from "./Logo";
import LoginButton from "./LoginButton";

/** Types */
type INavSet = { path: string; title: string };

type ILearnMoreSet = Array<INavSet>;

const learnMorePopoverSet: ILearnMoreSet = [
  { title: "About Us", path: "/about" },
  { title: "Travelers", path: "/learn-more/traveler" },
  { title: "Facilities", path: "/learn-more/facility" },
  { title: "Landlords", path: "/learn-more/landlord" },
  { title: "FAQs", path: "#" },
];

function NavItems(): any {
  return learnMorePopoverSet.map((nav: INavSet, index: number) => {
    return (
      <Link
        className="link-no-decoration width-fit hover-pointer"
        href={nav.path}
        key={nav.title + "-" + index}
      >
        {nav.title}
      </Link>
    );
  });
}

function Sidebar(): React.ReactElement {
  return (
    <Box className="p-7">
      <Box className="mb-7 hover-pointer">
        <Logo />
      </Box>
      <Box className="flex-column font-weight-semibold gap-spacer-6">
        <NavItems />
        <LoginButton isNavbarBtn={true} />
      </Box>
    </Box>
  );
}
export default Sidebar;
