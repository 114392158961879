import React from "react";

/** Vendor **/
import { Box, Container, Image, Heading, Text } from "@chakra-ui/react";

/** Custom Components **/
import PeopleBanner from "../../../dist/img/aboutus/frontline-healthcare.jpg";
import SchemaHeader from "../../shared/schema/Header";

/** Custom CSS **/
import "../aboutus.css";

const mission =
  "Traveling Jobs is committed to connect working professionals with a simplified process to career opportunities around the globe";

const vision = "Create economic opportunity for traveling professionals";

const values = [
  {
    title: "Simplify",
    desc: `We strive to create a environment to help the traveler experience become so easy that anyone can access and navigate with the slightest bit of effort.`,
  },
  {
    title: "Traveler Obsessed",
    desc: `We focus on catering to the traveler with the absolute best experience possible. We work hard to earn and keep their trust.`,
  },
  {
    title: "Value Add",
    desc: `No matter if you are in the C-suite or intern, no task is beneath you. Know that this company does not move without your utmost conviction to the mission.`,
  },
  {
    title: "Fail Fast",
    desc: `We are creating something new and innovative. This is a safe space to make mistakes, take risks, and build our company into the world's top remote worker placement facilitator.`,
  },
];

function Values(): any {
  return values.map((item, i) => (
    <Box className="about-value-container" key={`about-value-${i}`}>
      <SchemaHeader details={{}} page="about" />
      <Text className="font-size-xl font-weight-bold">{item.title}</Text>
      <Box>{item.desc}</Box>
    </Box>
  ));
}

function AboutUs() {
  return (
    <>
      <Box className="bg-blue-800 flex flex-column flex-center">
        <Container className="flex-between flex-column content-padding">
          <Image className="about-banner-img" src={PeopleBanner} />
        </Container>
      </Box>
      <Box className="about-container">
        <Box>
          <Container className="flex-between flex-column content-padding gap-spacer-7">
            <Heading
              as="h2"
              className="font-weight-bold font-size-5xl text-color-primary"
              size={["sm", "md", "lg"]}
            >
              Vision
            </Heading>
            <Box className="about-text-container">{vision}</Box>
          </Container>
        </Box>
        <Box className="bg-neutral-200">
          <Container className="flex-between flex-column content-padding gap-spacer-7">
            <Heading
              as="h2"
              className="font-weight-bold font-size-5xl text-color-primary"
              size={["sm", "md", "lg"]}
            >
              Mission
            </Heading>
            <Box className="about-text-container">{mission}</Box>
          </Container>
        </Box>
        <Box className="bg-blue-100">
          <Container className="flex-between flex-column content-padding gap-spacer-7">
            <Heading
              as="h2"
              className="font-weight-bold font-size-5xl text-color-primary"
              size={["sm", "md", "lg"]}
            >
              Values
            </Heading>
            <Box className="about-values-main-conatiner flex flex-column flex-center gap-spacer-8">
              <Values />
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
export default AboutUs;
