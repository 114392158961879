import React from "react";

/** Vendor **/
import { Box, Container } from "@chakra-ui/react";

function Hero() {
  return (
    <Box className="bg-blue-800 flex flex-center flex-column">
      <Container
        className="bg-blue-800 content-padding flex-between flex-column"
        height={100}
      />
    </Box>
  );
}
export default Hero;
