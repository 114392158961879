function Logo() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57 35V30C57 28.9391 56.5786 27.9217 55.8284 27.1716C55.0783 26.4214 54.0609 26 53 26H31C29.9391 26 28.9217 26.4214 28.1716 27.1716C27.4214 27.9217 27 28.9391 27 30V35"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M52 45V49H32V45C32 43.6739 31.4732 42.4021 30.5355 41.4645C29.5979 40.5268 28.3261 40 27 40C25.6739 40 24.4021 40.5268 23.4645 41.4645C22.5268 42.4021 22 43.6739 22 45V58H62V45C62 43.6739 61.4732 42.4021 60.5355 41.4645C59.5979 40.5268 58.3261 40 57 40C55.6739 40 54.4021 40.5268 53.4645 41.4645C52.5268 42.4021 52 43.6739 52 45Z"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M26 58V62"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M58 58V62"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12 62V24"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17 3H7L2 17V19H22V17L17 3Z"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M18 62H6"
        stroke="#212121"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
export default Logo;
