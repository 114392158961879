import React, { useRef } from "react";

/** Vendor **/
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  Popover,
  PopoverAnchor,
  PopoverTrigger as OrigPopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

/** Custom Components **/
import LoginButton from "../cards/LoginButton";
import GetStartedButton from "../cards/GetStartedButton";
import Logo from "../cards/Logo";
import ScreenSelector from "../cards/ScreenSelector";
import Sidebar from "../cards/Sidebar";
import ToggleButton from "../cards/ToggleButton";

/** CSS **/
import "../navbar.css";

type INavSet = { path: string; title: string };

type INavigationSet = Array<INavSet>;

type ILearnMoreSet = Array<INavSet>;

//Temp Fix because of issues conflict with React 18 types
//Ref: https://github.com/chakra-ui/chakra-ui/issues/5896
export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

const navigationSet: INavigationSet = [
  { title: "About Us", path: "/about" },
  { title: "Pricing", path: "/learn-more/traveler" },
];

const learnMorePopoverSet: ILearnMoreSet = [
  { title: "Travelers", path: "/learn-more/traveler" },
  { title: "Facilities", path: "/learn-more/facility" },
  { title: "Landlords", path: "/learn-more/landlord" },
  /** { title: "FAQs", path: "#" }, */
];

function NavbarDesktop(): React.ReactElement {
  const initRef = useRef<any>();

  return (
    <Box className="navbar" as="nav">
      <Container className="content">
        <Box className="flex-between">
          <Box className="flex-center flex-row gap-spacer-5">
            <Link to="/">
              <Logo />
            </Link>
          </Box>
          <Box>
            <ButtonGroup variant="ghost" style={{ marginLeft: "38px" }}>
              {navigationSet.map((nav: INavSet, index: number) => {
                return (
                  <React.Fragment key={`navbar-button-${index}`}>
                    {nav.title === "Pricing" ? (
                      <Popover initialFocusRef={initRef}>
                        {({ isOpen }) => (
                          <React.Fragment>
                            <PopoverTrigger>
                              <Button
                                key={nav.title}
                                className="bg-inherit font-weight-semibold font-size-md "
                                color={
                                  isOpen
                                    ? "var(--blue800)"
                                    : "var(--neutral1000)"
                                }
                              >
                                {nav.title}
                              </Button>
                            </PopoverTrigger>
                            <PopoverAnchor>
                              <Box
                                className="flex flex-center"
                                style={{
                                  margin: "-9px",
                                  paddingTop: "1px",
                                }}
                              >
                                <svg
                                  width="14"
                                  height="8"
                                  viewBox="0 0 14 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.99999 5.17192L11.95 0.221924L13.364 1.63592L6.99999 7.99992L0.635986 1.63592L2.04999 0.221924L6.99999 5.17192Z"
                                    fill={
                                      isOpen
                                        ? "var(--blue800)"
                                        : "var(--neutral1000)"
                                    }
                                  />
                                </svg>
                              </Box>
                            </PopoverAnchor>
                            <Portal>
                              <PopoverContent
                                style={{
                                  width: "189px",
                                  marginTop: "5px",
                                }}
                              >
                                <PopoverArrow />
                                <PopoverBody
                                  className="flex-column text-center"
                                  style={{ alignSelf: "center" }}
                                >
                                  {learnMorePopoverSet.map(
                                    (nav: INavSet, index: number) => {
                                      return (
                                        <div
                                          className="link"
                                          key={`navbar-sublink-${index}`}
                                        >
                                          <Link
                                            style={{ width: "fit-content" }}
                                            to={nav.path}
                                          >
                                            {nav.title}
                                          </Link>
                                        </div>
                                      );
                                    }
                                  )}
                                </PopoverBody>
                              </PopoverContent>
                            </Portal>
                          </React.Fragment>
                        )}
                      </Popover>
                    ) : (
                      <Button key={nav.title} className="bg-inherit">
                        <Link
                          key={nav.title + "-" + index}
                          className="link-no-decoration font-weight-semibold font-size-md "
                          to={nav.path}
                        >
                          {nav.title}
                        </Link>
                      </Button>
                    )}
                  </React.Fragment>
                );
              })}
            </ButtonGroup>
          </Box>
          <Box className="flex-center flex-row gap-spacer-5">
            <LoginButton />
            <GetStartedButton title={"Get Started"} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

function NavbarMobile(): React.ReactElement {
  const { isOpen, onClose, onToggle } = useDisclosure();
  return (
    <Box
      className="navbar"
      as="nav"
      style={{ position: "fixed", top: "0", width: "100%", zIndex: "10" }}
    >
      <Container className="navbar-content-container">
        <Box className="flex-between">
          <Box className="flex-center flex-row gap-spacer-5">
            <Link to="/">
              <Logo />
            </Link>
          </Box>
          <ToggleButton
            isOpen={isOpen}
            aria-label="Open Menu"
            onClick={onToggle}
          />
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            isFullHeight
            preserveScrollBarGap
            trapFocus={false}
          >
            <DrawerOverlay />
            <DrawerContent>
              <Sidebar />
            </DrawerContent>
          </Drawer>
        </Box>
      </Container>
    </Box>
  );
}

export default function Navbar() {
  return (
    <ScreenSelector>
      <NavbarDesktop />
      <NavbarMobile />
    </ScreenSelector>
  );
}
