import { Box, Button, Skeleton } from "@chakra-ui/react";

/** Authenication **/
import { useAuth0 } from "@auth0/auth0-react";

function LoginButton({ isNavbarBtn = false }: any): React.ReactElement {
  const { loginWithRedirect, isLoading } = useAuth0();

  return (
    <Box>
      <Skeleton isLoaded={!isLoading}>
        <Button
          className={
            isNavbarBtn
              ? "bg-blue-800 font-weight-semibold font-size-md text-color-secondary"
              : "bg-neutral-0 font-weight-semibold font-size-md"
          }
          onClick={() => loginWithRedirect({ screen_hint: "login" })}
        >
          Log In
        </Button>
      </Skeleton>
    </Box>
  );
}

export default LoginButton;
