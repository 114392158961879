import React from "react";

/** Vendor **/
import { Box, Container } from "@chakra-ui/react";

/** Custom Components **/
import Copyright from "../cards/Copyright";
import Logo from "../../../../dist/img/svg/brand/LogoDefault";
import Links from "../cards/Links";
import Socials from "../cards/Socials";

/** CSS **/
import "../footer.css";

function Footer(): JSX.Element {
  return (
    <Box className="footer-main-container">
      <Container>
        <Box className="footer-content">
          <Logo />
          <Links />
          <Socials />
          <Copyright />
        </Box>
      </Container>
    </Box>
  );
}
export default Footer;
