import React from "react";

/** Vendor **/
import { HashLink } from "react-router-hash-link";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Heading, Image } from "@chakra-ui/react";

function Hero({ details }: any): React.ReactElement {
  const { loginWithRedirect } = useAuth0();

  return (
    <Box className="content-container">
      <Box className="flex section-height flex-wrap hero-container">
        <Box className="bg-blue-800 flex-center flex-right hero-left width-50">
          <Box className="flex-column gap-spacer-6 section-content-width">
            <Heading
              as="h1"
              className="font-size-7xl font-weight-bold neutral-0"
              size={["sm", "md", "lg"]}
              style={{ maxWidth: "384px" }}
            >
              {details.title}
            </Heading>
            <Box
              className="font-size-2xl font-weight-normal neutral-0"
              style={{ maxWidth: "485px" }}
            >
              {details.sub}
            </Box>
            <Box className="gap-spacer-5">
              {window.location.pathname.includes("/traveler") ? (
                <Button
                  onClick={() => loginWithRedirect({ screen_hint: "signup" })}
                  className="mr-5 "
                >
                  {details.btnText}
                </Button>
              ) : (
                <HashLink to="#subscription">
                  <Button className="mr-5 ">{details.btnText}</Button>
                </HashLink>
              )}
            </Box>
          </Box>
        </Box>
        <Box className="width-50 flex-center flex-start hero-right bg-blue-800">
          <Image className="pos-absolute" src={details.img} />
        </Box>
      </Box>
    </Box>
  );
}

export default Hero;
