import React from "react";

/** Vendors **/
import { Box } from "@chakra-ui/react";

/** Custom Components **/
import PricingCard from "./PricingCard";

function PricingCardGenerator({
  bgColor,
  duration,
  features,
  isComingSoon,
  popular,
  prices,
  productIds,
  titles,
}: {
  bgColor: string[];
  duration: boolean;
  isComingSoon: boolean[];
  features: string[][];
  popular: boolean[];
  prices: number[][];
  productIds: string[];
  titles: string[];
}) {
  function createCards() {
    return prices[duration ? 0 : 1].map((price, i) => (
      <PricingCard
        color={bgColor[i]}
        duration={duration}
        features={features[i]}
        isComingSoon={isComingSoon[i]}
        key={`pricing-card-${i}`}
        popular={popular[i]}
        price={price}
        productId={productIds[i]}
        name={titles[i]}
      />
    ));
  }
  return <Box className="flex-end flex-wrap">{createCards()}</Box>;
}
export default PricingCardGenerator;
