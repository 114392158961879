import React from "react";

/** Custom Components **/
import ContactForm from "../cards/ContactForm";
import Title from "../cards/Title";
import Hero from "../cards/Hero";
import SchemaHeader from "../../shared/schema/Header";

function Contact() {
  return (
    <React.Fragment>
      <SchemaHeader details={{}} page="contact" />
      <Hero />
      <Title />
      <ContactForm />
    </React.Fragment>
  );
}
export default Contact;
