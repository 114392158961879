/** Vendors */
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  appId: process.env.REACT_APP_GOOGLE_APP_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  measurementId: process.env.REACT_APP_GOOGLE_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_GOOGLE_SENDER_ID,
  projectId: process.env.REACT_APP_GOOGLE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_GOOGLE_STORAGE_BUCKET,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const google = {
  app,
  analytics,
};

export default google;
