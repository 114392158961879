import React from "react";

/** Vendor **/
import { Box, Button, Container, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

/** Custom Components **/
import NotFoundImage from "../cards/NotFoundImage";

function Subscription(): React.ReactElement {
  return (
    <Box className="content-container section-content-padding bg-neutral-0">
      <Container>
        <Box className="flex-center flex-column">
          <NotFoundImage />
          <Link className="link-no-decoration" as={RouterLink} to={""}>
            <Button style={{ marginTop: "110px" }} variant="primary">
              Back to Homepage
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
}
export default Subscription;
