import React from "react";

/** Custom Components**/
import { Box, Container, Heading } from "@chakra-ui/react";

function QuestionCards({ details }: { details: any }) {
  return details.map((item: any, i: number) => {
    return (
      <Box
        className="flex-center flex-column gap-spacer-6 benefit-card p-7 flex-baseline bg-neutral-0 border-section-card"
        key={`question-card-${i}`}
      >
        <Box className="font-size-lg font-weight-semibold text-left">
          {item.question}
        </Box>
        <Box className="font-size-md text-color-primary text-left text-color-gray">
          {item.answer}
        </Box>
      </Box>
    );
  });
}

function FAQ({ details }: any): React.ReactElement {
  return (
    <Box className="content-container bg-blue-100" id="faq">
      <Container className="flex-column flex-column-center flex-center flex-wrap section-content-padding">
        <Box className="flex-column gap-spacer-6">
          <Heading
            as="h1"
            className="font-size-5xl font-weight-bold text-color-primary benefit-section-title text-center"
            size={["sm", "md", "lg"]}
          >
            Have Questions?
          </Heading>
        </Box>
        <Box className="benefit-container flex flex-wrap flex-column-center">
          <QuestionCards details={details} />
        </Box>
      </Container>
    </Box>
  );
}
export default FAQ;
