import React from "react";

/** Custom Components**/
import { Box, Button, Container, Heading, Image, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

/** Photos **/
import Facility from "../../../dist/img/process/facility_person.png";
import Landlord from "../../../dist/img/process/landlord_person.png";
import Nurse from "../../../dist/img/process/nurse_person.png";

const details = [
  {
    img: Nurse,
    path: "/learn-more/traveler",
    sub: "Find jobs all across the country",
    title: "Traveling Professionals",
  },
  {
    img: Facility,
    path: "/learn-more/facility",
    sub: "Post your jobs and manage applicants",
    title: "Facility Managers",
  },
  {
    img: Landlord,
    path: "/learn-more/landlord",
    sub: "Rent your space to a working professional",
    title: "Property Owners",
  },
];

function LearnMoreCards(): any {
  return details.map((item, i) => (
    <Box
      className="flex-center flex-column gap-spacer-6"
      key={`home-learn-more-${i}`}
    >
      <Image style={{ borderRadius: "16px" }} src={item.img} />
      <Box className="font-size-2xl font-weight-bold">{item.title}</Box>
      <Box className="font-size-lg text-color-primary text-center">
        {item.sub}
      </Box>
      <Link as={RouterLink} to={item.path} className="text-decoration-none">
        <Button className="font-size-sm" variant="primary">
          Learn More
        </Button>
      </Link>
    </Box>
  ));
}

function Process(): JSX.Element {
  return (
    <Box className="bg-blue-100 content-container">
      <Container className="flex-between flex-column content-padding section-content-padding">
        <Box className="text-center">
          <Heading
            as="h2"
            className="font-weight-bold font-size-5xl text-color-primary"
            size={["sm", "md", "lg"]}
          >
            Who Are You?
          </Heading>
          <Box className="font-size-2xl mb-6">
            We cover all the steps of the process
          </Box>
        </Box>
        <Box className="flex gap-spacer-8 flex-wrap flex-column-center">
          <LearnMoreCards />
        </Box>
      </Container>
    </Box>
  );
}

export default Process;
