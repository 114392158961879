function CheckIcon({
  color,
}: {
  color: { fill: string; stroke: string };
}): React.ReactElement {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_603_2918)">
        <path
          d="M12.6041 25.2081C19.5653 25.2081 25.2081 19.5653 25.2081 12.6041C25.2081 5.64284 19.5653 0 12.6041 0C5.64284 0 0 5.64284 0 12.6041C0 19.5653 5.64284 25.2081 12.6041 25.2081Z"
          fill={color["fill"]}
        />
        <path
          d="M6.90009 13.4189L10.1595 16.6784L18.3081 8.52979"
          stroke={color["stroke"]}
          strokeWidth="1.93909"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_603_2918">
          <rect width="25.2081" height="25.2081" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default CheckIcon;
