import React from "react";

/** Vendor **/
import { Outlet } from "react-router-dom";

/** Custom Components **/
import Navbar from "../navbar/views/Navbar";
import Footer from "../footer/views/Footer";

/** Custom Hooks */
import useLocationEffect from "../../../hooks/useLocationChange";

function AppLayout() {
  useLocationEffect((location) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  });

  return (
    <React.Fragment>
      <Navbar />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
}
export default AppLayout;
