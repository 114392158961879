/** Vendors **/
import { Link, ListItem, UnorderedList } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

/** Types **/
// import type { INavItem } from "../../../../types";

// const navItems: Array<INavItem> = [
const navItems: Array<any> = [
  { label: "Home", link: "/" },
  { label: "About Us", link: "/about" },
  { label: "Pricing", link: "" },
  {
    label: "Learn More",
    link: "/learn-more/traveler",
  },
  { label: "Contact", link: "/contact" },
  { label: "Legal", link: "/legal" },
];

function ListItems(): any {
  return navItems.map((item) => (
    <ListItem className="footer-link" key={`footer-link-${item.label}`}>
      <Link as={RouterLink} className="text-decoration-none" to={item.link}>
        {item.label}
      </Link>
    </ListItem>
  ));
}

function Links(): JSX.Element {
  return (
    <UnorderedList className="footer-links" key="footer-links">
      <ListItems />
    </UnorderedList>
  );
}
export default Links;
