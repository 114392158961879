import React, { useState } from "react";

/** Vendor **/
import { Box, Container, Heading } from "@chakra-ui/react";

/** Custom Compoennts **/
import DurationSwitcher from "../cards/DurationSwitcher";
import PricingCardGenerator from "../cards/PricingCardGenerator";

/** CSS **/
import "../subscription.css";

//The order is [basic, pro, enterprise]!!
//BUSINESS DATA
const businessFeatures: string[][] = [
  [
    "Applicant Comparison Tool",
    "Bulk Job Posting",
    "In-App Messaging",
    "Unlimited Job Postings",
  ],
  [
    "Applicant Managment",
    "Customized Reporting",
    "Customizable Business Page",
    "Payroll Integration",
  ],
  [
    "Contract Generation",
    "Personalized Onboarding",
    "Single Sign On",
    "Tax & Payroll Integration",
  ],
];
const businessPrices: number[][] = [
  [40, 80, 120],
  [400, 800, 1200],
];
// [[monthly],[annually]]
const businessStripeProductIds: string[][] = [
  [
    "price_1Lwy6ECuwjZhLEr7Xw7LdYZ5",
    "price_1Lwy6hCuwjZhLEr75QRRnYk1",
    "price_1Lwy79CuwjZhLEr7LrPgLwWj",
  ],
  [
    "price_1Lwy6ECuwjZhLEr7b6hGiUN9",
    "price_1Lwy6hCuwjZhLEr71uL7VUql",
    "price_1Lwy79CuwjZhLEr7mV9XfjRI",
  ],
];

//LANDLORD DATA
//What does airbnb offer?
//What does airbnb not offer than can make us different?
//TODO: Explain the asterisk with small print expining that you can buy background checks in app
const landlordFeatures: string[][] = [
  [
    "Traveler History",
    "Unlimited Posting",
    "Self Serve Background Checks with Checkr",
  ],
  [
    "Add Property Managers",
    "Become Preferred Housing Provider",
    "In-App Messaging",
    "Lease Reminders",
    "Tenant Management",
  ],
];
const landlordPrices: number[][] = [
  [30, 50],
  [300, 500],
];
// [[monthly],[annually]]
const landlordStripeProductIds: string[][] = [
  ["price_1Lwy7hCuwjZhLEr7C0KNEy2H", "price_1Lwy8LCuwjZhLEr7BCll6mU0"],
  ["price_1Lwy7hCuwjZhLEr7ewbIsAXi", "price_1Lwy8LCuwjZhLEr7EwsPWKFT"],
];

//SHARED DATA -- [basic, pro, enterprise] in that order!!
const isComingSoon: boolean[] = [true, true, true];
const isPopular: boolean[] = [false, true, false];
const tierColors: string[] = [
  "var(--neutral200)",
  "var(--blue800)",
  "var(--blue100)",
];
const tierTitles: string[] = ["Basic", "Pro", "Enterprise"];

function Subscription(): React.ReactElement {
  const domain = new URL(window.location.href).pathname.includes("landlord");
  const [duration, setDuration] = useState<boolean>(true);

  return (
    <Box
      id="subscription"
      className="content-container section-content-padding bg-neutral-0"
    >
      <Container>
        <Heading
          as="h1"
          className="font-weight-bold text-center primary-color mb-7"
          size={["md", "md", "lg"]}
        >
          {domain ? "Landlord " : "Business "}
          Pricing
        </Heading>
        <DurationSwitcher duration={duration} setDuration={setDuration} />
        <PricingCardGenerator
          bgColor={tierColors}
          duration={duration}
          features={domain ? landlordFeatures : businessFeatures}
          isComingSoon={isComingSoon}
          productIds={
            domain
              ? landlordStripeProductIds[duration ? 0 : 1]
              : businessStripeProductIds[duration ? 0 : 1]
          }
          popular={isPopular}
          prices={domain ? landlordPrices : businessPrices}
          titles={tierTitles}
        />
      </Container>
    </Box>
  );
}
export default Subscription;
