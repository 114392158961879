import React from "react";

/** Vendors **/
import { Box, Text } from "@chakra-ui/react";

interface IPriceDisplayProps {
  currency: string;
  duration: boolean;
  price: number;
}

function PricingDisplay(props: IPriceDisplayProps) {
  const { currency, duration, price } = props;
  return (
    <Box my="5" className="flex flex-center font-weight-extrabold width-100">
      <Text lineHeight="1" letterSpacing="tighter" className="font-size-5xl">
        {currency + price}
      </Text>
      <Box className="duration-text flex-column font-weight-normal p1">
        <Text>{duration ? "/monthly" : "/annually"}</Text>
      </Box>
    </Box>
  );
}
export default PricingDisplay;
