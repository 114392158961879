/** Vendors **/
import { Link, ListItem, UnorderedList } from "@chakra-ui/react";

/** Custom Components **/
import FacebookSolid from "../../../../dist/img/svg/social/FacebookSolid";
import LinkedinSolid from "../../../../dist/img/svg/social/LinkedinSolid";

const navItems: Array<any> = [
  {
    label: <LinkedinSolid />,
    link: "https://www.linkedin.com/company/travelingjobs/",
  },
  {
    label: <FacebookSolid />,
    link: "https://www.facebook.com/profile.php?id=100086939281514",
  },
];

function ListItems(): any {
  return navItems.map((item: any) => (
    <ListItem className="footer-social-icon" key={`footer-link-${item.link}`}>
      <Link href={item.link} isExternal>
        {item.label}
      </Link>
    </ListItem>
  ));
}

function Socials(): JSX.Element {
  return (
    <UnorderedList className="footer-links-social">
      <ListItems />
    </UnorderedList>
  );
}
export default Socials;
