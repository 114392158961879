function FacebookSolid({
  color = "#FFFFFF",
  height = 32,
  width = 32,
}: {
  color?: string;
  height?: number;
  width?: number;
}): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.1429 1.71436H2.85715C2.22501 1.71436 1.71429 2.22507 1.71429 2.85721V29.1429C1.71429 29.7751 2.22501 30.2858 2.85715 30.2858H29.1429C29.775 30.2858 30.2857 29.7751 30.2857 29.1429V2.85721C30.2857 2.22507 29.775 1.71436 29.1429 1.71436ZM25.8429 10.0536H23.5607C21.7714 10.0536 21.425 10.9036 21.425 12.1536V14.9072H25.6964L25.1393 19.2179H21.425V30.2858H16.9714V19.2215H13.2464V14.9072H16.9714V11.7286C16.9714 8.03936 19.225 6.02864 22.5179 6.02864C24.0964 6.02864 25.45 6.1465 25.8464 6.20007V10.0536H25.8429Z"
        fill={color}
      />
    </svg>
  );
}
export default FacebookSolid;
