import { useEffect } from "react";

/** Vendors */
import { useLocation } from "react-router-dom";

/** Types */
import type { Location } from "react-router";

export default function useLocationEffect(callback: (location: Location) => void) {
  const location = useLocation();

  useEffect(() => {
    callback(location);
  }, [location, callback]);
}
