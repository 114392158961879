import React from "react";

/** Vendors **/
import { useAuth0 } from "@auth0/auth0-react";
import { Box, BoxProps, Button, List, Text } from "@chakra-ui/react";

/** Custom Components **/
import PricingDetail from "./PricingDetail";
import PricingDisplay from "./PricingDisplay";
import PricingWrapper from "./PricingWrapper";

interface IPricingCardProps extends BoxProps {
  color: string;
  duration: boolean;
  features: string[];
  isComingSoon?: boolean;
  link?: string;
  popular?: boolean;
  price: number;
  productId: string;
  name: string;
}

function PricingCard(props: IPricingCardProps) {
  const domain = new URL(window.location.href).pathname.includes("landlord");
  const { loginWithRedirect } = useAuth0();
  const {
    color,
    duration,
    features,
    isComingSoon,
    popular,
    price,
    productId,
    name,
  } = props;

  return (
    <PricingWrapper color={color}>
      <Box className="flex-column flex-column-center">
        <Box className="font-size-3xl-b font-weight-bold popular-container">
          {domain ? "Landlord " + name : "Business " + name}
          {popular && <Box className="popular-badge">Popular</Box>}
        </Box>
        <PricingDisplay currency="$" duration={duration} price={price} />
      </Box>
      <Text>
        Features
        {name === "Pro" &&
          " everything in " +
            (domain ? "Landlord " : "Business ") +
            " Basic and"}
        {name === "Enterprise" &&
          " everything in  " +
            (domain ? "Landlord " : "Business ") +
            "  Pro and"}
      </Text>
      <List className="feature-list">
        {features.map((feature, idx) => (
          <PricingDetail color={color} key={idx}>
            {feature}
          </PricingDetail>
        ))}
      </List>
      <Box className="tier-button-container ">
        <Button
          className="font-weight-bold tier-button"
          colorScheme={popular ? "whiteAlpha" : "blue"}
          disabled={isComingSoon}
          onClick={() =>
            loginWithRedirect({
              fragment: productId,
            })
          }
        >
          {isComingSoon ? "Coming Soon" : "Get Started"}
        </Button>
      </Box>
    </PricingWrapper>
  );
}
export default PricingCard;
