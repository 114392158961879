function LinkedinSolid({
  color = "#FFFFFF",
  height = 32,
  width = 32,
}: {
  color?: string;
  height?: number;
  width?: number;
}): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.1429 1.71436H2.85715C2.22501 1.71436 1.71429 2.22507 1.71429 2.85721V29.1429C1.71429 29.7751 2.22501 30.2858 2.85715 30.2858H29.1429C29.775 30.2858 30.2857 29.7751 30.2857 29.1429V2.85721C30.2857 2.22507 29.775 1.71436 29.1429 1.71436ZM10.1893 26.0608H5.95001V12.4251H10.1893V26.0608ZM8.07144 10.5608C7.58546 10.5608 7.1104 10.4167 6.70632 10.1467C6.30225 9.87669 5.98731 9.49293 5.80133 9.04395C5.61536 8.59496 5.5667 8.10092 5.66151 7.62428C5.75632 7.14764 5.99034 6.70982 6.33398 6.36618C6.67761 6.02254 7.11543 5.78852 7.59207 5.69371C8.06871 5.5989 8.56276 5.64756 9.01175 5.83354C9.46073 6.01951 9.84448 6.33445 10.1145 6.73853C10.3845 7.1426 10.5286 7.61766 10.5286 8.10364C10.525 9.46078 9.42501 10.5608 8.07144 10.5608ZM26.0607 26.0608H21.825V19.4286C21.825 17.8465 21.7964 15.8144 19.6214 15.8144C17.4179 15.8144 17.0786 17.5358 17.0786 19.3144V26.0608H12.8464V12.4251H16.9107V14.2894H16.9679C17.5322 13.2179 18.9143 12.0858 20.9786 12.0858C25.2714 12.0858 26.0607 14.9108 26.0607 18.5822V26.0608Z"
        fill={color}
      />
    </svg>
  );
}
export default LinkedinSolid;
