import axios from "axios";

const createContactRequest = async (request) => {
  const response = await axios.put("/request", request);
  return response;
};

const request = {
  create: createContactRequest,
}

export default request;