import React from "react";

/** Vendor **/
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import {
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";

/** Custom APIs */
import requestMethods from "../../../api/request";

/** Types */
import type { IRequest } from "../../../types";
import type { UseToastOptions } from "@chakra-ui/toast";

function ContactForm() {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
  } = useForm();

  const toast = useToast();
  const toastProps: UseToastOptions = {
    duration: 7000,
    isClosable: true,
    position: "top-right",
  };

  /** Event Listeners */
  const actions = {
    onSubmit: (request: IRequest) => {
      requestMethods
        .create({ method: "PUT", request })
        .then(() => {
          toast({
            ...toastProps,
            description:
              "Thanks! We've sent your message to our team. We'll be in touch soon.",
            status: "success",
            title: "Submission Successful",
          });
        })
        .catch((error) => {
          console.log(error);
          toast({
            ...toastProps,
            description:
              "Sorry! There was an error with your request. Our team is looking into it. Please try again later.",
            status: "error",
            title: "Submission Unsuccessful",
          });
        })
        .then(() => reset());
    },
  };

  return (
    <Container maxW="container.md" mb={10}>
      <form
        onSubmit={handleSubmit((values) =>
          actions.onSubmit(values as IRequest)
        )}
      >
        <Flex>
          <FormControl isInvalid={Boolean(errors.given_name)} pr={4} my={4}>
            <FormLabel>First name</FormLabel>
            <Input
              errorBorderColor="red.300"
              id="given_name"
              placeholder="Jane"
              _placeholder={{ opacity: 1, color: "gray.400" }}
              type="text"
              variant="outline"
              {...register("given_name", {
                required: "Required",
                minLength: {
                  value: 3,
                  message: "Minimum length should be 3",
                },
              })}
            />
            <FormErrorMessage>
              {(errors.given_name && errors.given_name.message) as string}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.family_name)} my={4}>
            <FormLabel>Last name</FormLabel>
            <Input
              errorBorderColor="red.300"
              id="family_name"
              placeholder="Smith"
              _placeholder={{ opacity: 1, color: "gray.400" }}
              type="text"
              variant="outline"
              {...register("family_name", {
                required: "Required",
                minLength: {
                  value: 3,
                  message: "Minimum length should be 3",
                },
              })}
            />
            <FormErrorMessage>
              {(errors.family_name && errors.family_name.message) as string}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <FormControl isInvalid={Boolean(errors.email)} my={4}>
          <FormLabel>Email</FormLabel>
          <Input
            errorBorderColor="red.300"
            id="email"
            placeholder="jane.smith@email.com"
            _placeholder={{ opacity: 1, color: "gray.400" }}
            type="email"
            variant="outline"
            {...register("email", {
              required: "Required",
              minLength: { value: 3, message: "Minimum length should be 3" },
            })}
          />
          <FormErrorMessage>
            {(errors.email && errors.email.message) as string}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.comments)} my={4}>
          <FormLabel>Comments</FormLabel>
          <Textarea
            errorBorderColor="red.300"
            id="comments"
            placeholder="Questions or comments"
            _placeholder={{ opacity: 1, color: "gray.400" }}
            variant="outline"
            {...register("comments", {
              required: "Required",
              minLength: { value: 3, message: "Minimum length should be 3" },
            })}
          />
          <FormErrorMessage>
            {(errors.comments && errors.comments.message) as string}
          </FormErrorMessage>
        </FormControl>
        <Center>
          <Button
            colorScheme="blue"
            isLoading={isSubmitting}
            my={4}
            rightIcon={<ArrowForwardIcon />}
            size="md"
            type="submit"
          >
            Submit
          </Button>
        </Center>
      </form>
    </Container>
  );
}
export default ContactForm;
