import React from "react";

/** Vendor **/
import { Box, BoxProps } from "@chakra-ui/react";

function PricingWrapper(props: BoxProps & { highlight?: boolean }) {
  const { color, highlight, ...rest } = props;

  return (
    <Box
      className="card-container"
      bg={color}
      textColor={color === "var(--blue800)" ? "var(--neutral0)" : "inherit"}
      {...rest}
    />
  );
}
export default PricingWrapper;
