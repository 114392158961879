const schema = () => {
  return {
    json: [
      {
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: "Contact - Traveling Jobs",
        url: `${process.env.REACT_APP_DOMAIN}/contact`,
      },
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: process.env.REACT_APP_DOMAIN,
            name: "Home",
          },
          {
            "@type": "ListItem",
            position: 2,
            item: `${process.env.REACT_APP_DOMAIN}/contact`,
            name: "Contact",
          },
        ],
      },
    ],
    description: "",
    title: "Contact - Traveling Jobs",
  };
};

export default schema;
