import React from "react";

/** Custom Components**/
import { Box } from "@chakra-ui/react";

function RotatingText() {
  return (
    <React.Fragment>
      <Box className="scroller">
        <Box>
          <div className="scroller-word-1" key="rotator-0">
            Jobs
          </div>
          <div className="scroller-word-2" key="rotator-1">
            Nurses
          </div>
          <div className="scroller-word-3" key="rotator-2">
            Dentists
          </div>
          <div className="scroller-word-4" key="rotator-3">
            Physicans
          </div>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default RotatingText;
