import React from "react";

/** Vendor **/
import { Box, ListItem, ListItemProps, Text } from "@chakra-ui/react";

/** Custom Components **/
import CheckIcon from "./CheckIcon";

interface IFeaturesItemProps extends ListItemProps {
  color: string;
}

function PricingDetail(props: IFeaturesItemProps) {
  const { color, children, ...rest } = props;
  return (
    <ListItem className="item flex-start font-weight-medium" {...rest}>
      <Box>
        <CheckIcon
          color={
            color === "var(--blue800)"
              ? { fill: "var(--neutral300", stroke: "var(--blue800)" }
              : { fill: "var(--blue800)", stroke: "var(--neutral300" }
          }
        />
      </Box>
      <Text as="span" display="inline-block">
        {children}
      </Text>
    </ListItem>
  );
}
export default PricingDetail;
