import React from "react";

/** Vendors **/
import { useParams } from "react-router-dom";

/** Custom Components**/
import Hero from "../cards/Hero";
import Benefits from "../cards/Benefits";
import FAQ from "../cards/FAQ";
import SchemaHeader from "../../shared/schema/Header";
import Steps from "../../shared/steps/Steps";
import Subscription from "../../subscription/views/Subscription";
import Testimonials from "../cards/Testimonals";
import TravelerCTA from "../cards/TravelerCTA";

/** Logos **/
import AppMessage from "../../../dist/img/svg/marketing/traveler/AppMessage";
import CustomerSupport from "../../../dist/img/svg/marketing/traveler/CustomerSupport";
import Diversify from "../../../dist/img/svg/marketing/landlord/DiversifyInvesments";
import FeedBack from "../../../dist/img/svg/marketing/facility/Feedback";
import Generate from "../../../dist/img/svg/marketing/landlord/GenerateIncome";
import Housing from "../../../dist/img/svg/marketing/traveler/Housing";
import MeetPeople from "../../../dist/img/svg/marketing/traveler/MeetPeople";
import Onboarding from "../../../dist/img/svg/marketing/facility/OnboardingChecklists";
import ShareHome from "../../../dist/img/svg/marketing/landlord/ShareHome";
import SimplifiedProcess from "../../../dist/img/svg/marketing/facility/SimplifiedProcess";
import Streamlined from "../../../dist/img/svg/marketing/facility/StreamlinedProcess";
import Transport from "../../../dist/img/svg/marketing/traveler/Transport";
import TrendingLocations from "../../../dist/img/svg/marketing/traveler/TrendingLocations";
import WorkingProfessionals from "../../../dist/img/svg/marketing/landlord/WorkingProfessionals";

/** CSS **/
import "../home.css";
import "./learnMore.css";

/** Photos **/
import Traveler from "../../../dist/img/learnmore/traveler.png";
import Facility from "../../../dist/img/learnmore/facility.png";
import Landlord from "../../../dist/img/learnmore/landlord.png";

//TODO: Fix any types
// Hero Details = {{traveler}, {facility}, {landlord}}
const entityHeroDetails: any = {
  traveler: {
    btnText: "Get Started for Free",
    img: Traveler,
    sub: "Create a FREE account and get access to jobs all over the nation.",
    title: "Start Your Next Journey With Us",
  },
  facility: {
    btnText: "Create Your Facility Account",
    img: Facility,
    sub: "Customize the steps when it comes to housing, transport, pier diem, salarly, and more! ",
    title: "Take Control of Your Applicant Pool All in One Place",
  },
  landlord: {
    btnText: "Create Your Landlord Account",
    img: Landlord,
    sub: "Reduce uncertainty of who you are renting to",
    title: "Rent Out Your Extra Space to a Working Professional",
  },
};

// Benfits Details = {{traveler[]}, {facility[]}, {landlord[]}}
const entityBenefitDetails: any = {
  traveler: [
    {
      icon: <AppMessage />,
      sub: "No back and forth between apps. Your life is busy enough. Keep all communication in one place.",
      title: "In-App Messaging",
    },
    {
      icon: <TrendingLocations />,
      sub: "Discover where the most in-demand jobs are being requested.",
      title: "Trending Locations",
    },
    {
      icon: <Housing />,
      sub: "Selection of top short-term and long-term rentals.",
      title: "Housing",
    },
    {
      icon: <MeetPeople />,
      sub: "Connect with travelers in your career field and work together.",
      title: "Meet New People",
    },
    {
      icon: <Transport />,
      sub: "Job providers can connect you with travel recommendations.",
      title: "Transportation",
    },
    {
      icon: <CustomerSupport />,
      sub: "Need Help? Our team is standing by to help you get your next traveling job.",
      title: "Customer Support",
    },
  ],
  facility: [
    {
      icon: <AppMessage />,
      sub: "No back and forth between apps. Your life is busy enough. Keep all communication in one place.",
      title: "In-App Messaging",
    },
    {
      icon: <Streamlined />,
      sub: "Create tailored applicant flows where you can fully customize the benefits and offerings in the employee packages.",
      title: "Streamlined Appliant Flow",
    },
    {
      icon: <SimplifiedProcess />,
      sub: `We take the word "difficult" out of the equation and save you time. Our process simplifies the verification process.`,
      title: "Simplified Process",
    },
    {
      icon: <Onboarding />,
      sub: "Want your new hire to hit the ground running? We can help you streamline first day onboarding paperwork and securely gather all information requirements.",
      title: "Onboarding Checklists",
    },
    {
      icon: <FeedBack />,
      sub: "Foster brand reputation with constructive feedback from travelers, and maintain a positive brand.",
      title: "Traveler Feedback",
    },
    {
      icon: <CustomerSupport />,
      sub: "Our team is here to help. Get a response by the next business day.",
      title: "Customer Support",
    },
  ],
  landlord: [
    {
      icon: <WorkingProfessionals />,
      sub: "Get peace of mind that your house is being rented by a working professional.",
      title: "Rent To Working Professionals",
    },
    {
      icon: <Generate />,
      sub: (
        <p>
          Keep your available units/properties rented consistently by becoming a
          &nbsp;
          <a className="hyperlink" href="#subscription">
            Landlord Pro
          </a>
          &nbsp;and maximize month over month revenue by ensuring your space is
          always filled.
        </p>
      ),
      title: "Generate Income",
    },
    {
      icon: <Diversify />,
      sub: "If you have available rental units or need a passive income stream, you’ve come to the right place.",
      title: "Diversify Investments",
    },
    {
      icon: <ShareHome />,
      sub: "Have an interesting view or maybe you live in a prime location? Let people know about it!",
      title: "Share Your Unique Home",
    },
    {
      icon: <MeetPeople />,
      sub: "You’ll have the opportunity to get to know your tenants and potential referral opportunities.",
      title: "Meet New People",
    },
    {
      icon: <CustomerSupport />,
      sub: "Our team is here to help. Get a response by the next business day.",
      title: "Customer Support",
    },
  ],
};

const entityBenefitTitles: any = {
  facility: "Create a Stress-Free Process For Your Potiential Employees",
  landlord: "List Your Property and Earn Passive Income",
  traveler: "Finding Your Next Traveling Job Experience Made Easy",
};

const entityQuestions: any = {
  traveler: [
    {
      answer: "We have businesses that post their jobs on the platform.",
      question: "Who posts the jobs?",
    },
    {
      answer:
        "Yes, there is a chat portal which will respond wihtin normal business hours. M-F 9AM to 5PM.",
      question: "Is there customer service?",
    },
    {
      answer:
        "For lease questions please follow the conflict resolution steps outlined in your lease.",
      question: "If I have issues with my housing situation who do I contact?",
    },
    {
      answer: (
        <p>
          We use a third party application&nbsp;
          <a
            className="hyperlink"
            href="https://verifiable.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Verifiable
          </a>
          &nbsp;in order to verify that you have those licenses and credentials.
        </p>
      ),
      question: "How do you verify our licenses and credentials?",
    },
    {
      answer:
        "Depends. Housing providers have the choice to require a background check.",
      question: "Does housing require background checks?",
    },
    {
      answer:
        "Depends. Business facilititors who posted the job can request you get a background check.",
      question: "Do jobs require background checks?",
    },
  ],
  facility: [
    {
      answer: (
        <p>
          Yes! We are using&nbsp;
          <a
            className="hyperlink"
            href="https://checkr.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Checkr
          </a>
          &nbsp;as our provider for background checks.
        </p>
      ),
      question: "Can I run a background check in app?",
    },
    {
      answer:
        "It’s on the traveler to schedule housing within the app. So that take a load off your shoulders. You can either provide or not provide stipends for housing. You choose whats covered in the offer package for future employees.",
      question: "How do I get connected to housing?",
    },
    {
      answer:
        "You can request a license/credentials verification with a click on a button on the applicants profile.",
      question:
        "How do you verify licenses and credentials for the professional?",
    },
    {
      answer: "There is a unlimited amount of gigs that you can post!",
      question: "How many gigs could I post?",
    },
    {
      answer:
        "No. Your posts dont expire but if you arent having any appliants we recommend archiving and starting a new post that has updated terms so nurses are more apt to applying. ",
      question: "Do my postings ever expire?",
    },
    {
      answer:
        "Yes you exculde/include any amenities in any offer package. If you recommend a your preferred housing provider that may help out the traveler more than just saying no. ",
      question:
        "If I dont want to offer housing can I skip that in the offer package?",
    },
  ],
  landlord: [
    {
      answer: (
        <p>
          Yes! We are using&nbsp;
          <a
            className="hyperlink"
            href="https://checkr.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Checkr
          </a>
          &nbsp;as our provider for background checks.
        </p>
      ),
      question: "Can I run background checks in app?",
    },
    {
      answer:
        "Tenant insurance is the responsibility of the landlord and the tenant.",
      question: "Are you offering any insurance for travelers?",
    },
    {
      answer:
        "Stuff happens, that’s why we highly encourage our landlords to get tenant insurance. You can properly document that and give it to your insurance company.",
      question: "What if a tenant damages my property?",
    },
    {
      answer:
        "The job provider sets the length of the initial stay. You have the choice to either  accept or decline. You’ll find that stay are usaully longer and nurses often extend. ",
      question:
        "Do traveler contracts have a minimal employment timeframe and how does this effect my rental agreement?",
    },
    {
      answer:
        "Payment is between the tenant and you based on your lease agreement and your own payment options. Traveling Jobs does not handle lease payments at this time.",
      question: "How do I get paid?",
    },
    {
      answer: "That is a feature that is coming soon to the application.",
      question: "Can I add my property manager to my account? ",
    },
  ],
};

const entityStepsDetails: any = {
  facility: [
    {
      sub: "Register as a Facility if you're looking to recruit employees and list new job opportunities.",
      title: "Sign-up as a Facility",
    },
    {
      sub: "Find Travelers based on their certifications, experience, location, and more. Invite Travelers to apply for your jobs if you see they meet your criteria.",
      title: "Search for Employees",
    },
    {
      sub: "Be proactive about your recruitment tactics. Easily collect all onboarding resources and get to work with one of our traveling professionals.",
      title: "Hire New Employees",
    },
  ],
  landlord: [
    {
      sub: "Register as a Landlord if you're looking to rent your  properties to traveling professionals.",
      title: "Sign-up as a Landlord",
    },
    {
      sub: "Specify your tenant requirements and property amenities. See how your properties compare to the current market.",
      title: "Post Listings",
    },
    {
      sub: "Rent your properties to professionals and earn smarter with Traveling Jobs.",
      title: "Book Next Tenant",
    },
  ],
};
const entityStepsTitles: any = {
  facility: "3 Easy Steps to Meet Your Staffing Needs",
  landlord: "3 Easy Steps to Get You Connected & Renting",
};

function LearnMore() {
  let { entity } = useParams();

  if (!["traveler", "facility", "landlord"].some((item) => item === entity)) {
    window.location.pathname = "/";
  }

  return (
    <React.Fragment>
      <SchemaHeader details={{}} page={`learn-more-${entity}`} />
      <Hero details={entityHeroDetails[entity!]} />
      <Benefits
        details={entityBenefitDetails[entity!]}
        title={entityBenefitTitles[entity!]}
      />

      {entity === "traveler" ? (
        <TravelerCTA />
      ) : (
        <Steps
          details={entityStepsDetails[entity!]}
          title={entityStepsTitles[entity!]}
        />
      )}
      {entity !== "traveler" && <Subscription />}
      <FAQ details={entityQuestions[entity!]} />
      <Testimonials />
    </React.Fragment>
  );
}

export default LearnMore;
