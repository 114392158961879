/** Vendors */
import { Button, Skeleton } from "@chakra-ui/react";

/** Authenication **/
import { useAuth0 } from "@auth0/auth0-react";

function GetStartedButton({ title }: { title: string }): React.ReactElement {
  const { isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  const authEventHandler = () => {
    if (isAuthenticated) {
      logoutWithRedirect();
    } else {
      loginWithRedirect({ screen_hint: "signup" });
    }
  };

  return (
    <Skeleton isLoaded={!isLoading}>
      <Button
        className="bg-blue-800 text-color-secondary font-size-md"
        onClick={() => authEventHandler()}
      >
        {title}
      </Button>
    </Skeleton>
  );
}

export default GetStartedButton;
