import React, { useState, useCallback } from "react";

/** Vendor **/
import {
  Box,
  Button,
  Container,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

/** Custom Components **/
import ChevronLeft from "../../../dist/img/svg/testimonial/ChevronLeft";
import ChevronRight from "../../../dist/img/svg/testimonial/ChevronRight";

/** CSS **/
import "swiper/css";
import "swiper/css/navigation";
import "../testimonials.css";

function Testimonials(): React.ReactElement {
  const [isSmallerThan1200] = useMediaQuery("(max-width: 1200px)");
  const [swiperRef, setSwiperRef] = useState<any>();

  const handleLeftClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slidePrev();
  }, [swiperRef]);

  const handleRightClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slideNext();
  }, [swiperRef]);

  const data = [
    {
      key: 1,
      photo: "https://bit.ly/dan-abramov",
      name: "Lisa Leslie",
      title: "Nurse Clinic Coordinator",
      quote:
        '"I am so pleased with this product. What great service, I love it! I would also like to say thank you to all the staff. Traveling Jobs is exactly what our business was lacking. This really saves me time and effort."',
    },
    {
      key: 2,
      photo: "https://bit.ly/dan-abramov",
      name: "Andrea Arthur",
      title: "MSN, RN, APRN, OCN",
      quote:
        "\"I've booked a few great gigs through Traveling Jobs that were set up easily. It's been really wonderful working more directly with employers. Thanks for the helpful platform.\"",
    },
    {
      key: 3,
      photo: "https://bit.ly/dan-abramov",
      name: "Earl P.",
      title: "Property Owner",
      quote: '"Traveling Jobs should be nominated for service of the year."',
    },
    {
      key: 4,
      photo: "https://bit.ly/dan-abramov",
      name: "Kevin Godwin",
      title: "RN",
      quote:
        '"Very easy to use. Makes working on the go more reliable and transparent."',
    },
  ];
  const testimonials = data.map((element, i: number) => {
    return (
      <SwiperSlide key={`testimonial-card-${i}`}>
        <Container className="slide-container">
          <Box className="slide-content-left">
            <Image
              boxSize="55px"
              className="slide-photo"
              objectFit="cover"
              src={element.photo}
            />
            <Box className="slide-text-container">
              <Text className="slide-text-name">{element.name}</Text>
              <Text className="slide-text-title">{element.title}</Text>
            </Box>
          </Box>
          <Text className="slide-content-right tQuote" noOfLines={[5, 8]}>
            {element.quote}
          </Text>
        </Container>
      </SwiperSlide>
    );
  });
  return (
    <Box>
      <Container className="testimonials-container">
        <Heading
          as="h2"
          className="h2"
          fontWeight="700"
          size={["sm", "md", "lg"]}
        >
          What Users Are Saying About Traveling Jobs
        </Heading>
        <Box className="swiper-container flex flex-center">
          {!isSmallerThan1200 && (
            <Button className="bg-inherit" onClick={handleLeftClick}>
              <ChevronLeft />
            </Button>
          )}
          <Swiper
            onSwiper={setSwiperRef}
            breakpoints={{
              // when window width is >= 300px
              300: {
                slidesPerView: 1,
                spaceBetween: 60,
              },
              // when window width is >= 768px
              900: {
                slidesPerGroup: 2,
                slidesPerView: 2,
                spaceBetween: 60,
              },
            }}
            loop={true}
            modules={[Navigation, Pagination]}
            pagination={{
              el: ".navigation",
              clickable: true,
            }}
          >
            {testimonials}
          </Swiper>
          <Box className="navigation"></Box>
          {!isSmallerThan1200 && (
            <Button className="bg-inherit" onClick={handleRightClick}>
              <ChevronRight />
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
}
export default Testimonials;
