const schema = () => {
  return {
    json: [
      {
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: "The Legal Stuff - Traveling Jobs",
        url: process.env.REACT_APP_DOMAIN,
      },
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: process.env.REACT_APP_DOMAIN,
            name: "Home",
          },
          {
            "@type": "ListItem",
            position: 2,
            item: `${process.env.REACT_APP_DOMAIN}/legal`,
            name: "Legal",
          },
        ],
      },
    ],
    description: "Legal and How It Works",
    title: "The Legal Stuff - Traveling Jobs",
  };
};

export default schema;
