import React from "react";

/** Vendor **/
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

/** Custom Components**/
import { Box, Image } from "@chakra-ui/react";

/** Photos **/
import Image1 from "../../../dist/img/home/teamwork_dreamwork.png";
import Image2 from "../../../dist/img/home/confident_doctor.png";
import Image3 from "../../../dist/img/home/okay_doctor.png";
import Image4 from "../../../dist/img/home/phys_clipboard.png";

// import "./rotating-text.css";
const heroPictures = [Image1, Image2, Image3, Image4];

function Slides(): any {
  return heroPictures.map((item, i) => (
    <Image
      alt="Traveling Jobs Image"
      className={"img" + (i + 1)}
      key={`hero-image-${i}`}
      src={item}
    />
  ));
}

function RotatingText(): JSX.Element {
  return (
    <Box
      style={{ margin: "auto", position: "relative", overflow: "hidden" }}
      className="height-100"
    >
      <Box className="hero-slider">
        <Slides />
      </Box>
    </Box>
  );
}

export default RotatingText;
