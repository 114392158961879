function Error() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      width="48"
      viewBox="0 0 48 48"
    >
      <g fill="#ffadad">
        <path
          d="M24,1A23,23,0,1,0,47,24,23.026,23.026,0,0,0,24,1Zm0,37a2,2,0,1,1,2-2A2,2,0,0,1,24,38Zm1.045-7.829H22.994L22,9.487h4.043Z"
          fill="#ffadad"
        ></path>
      </g>
    </svg>
  );
}
export default Error;
