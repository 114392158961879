import React from "react";

/** Vendor **/
import {
  Flex,
  HStack,
  Stack,
  Switch,
  Text,
  VisuallyHidden,
} from "@chakra-ui/react";

function DurationSwitcher({
  duration,
  setDuration,
}: {
  duration: boolean;
  setDuration: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement {
  return (
    <Stack className="flex-center gap-spacer-8">
      <VisuallyHidden as="label" htmlFor="switcher">
        Switch price duration
      </VisuallyHidden>
      <HStack spacing="3">
        <Text fontWeight={duration ? 700 : 500}>Monthly</Text>
        <Switch
          className="durationSwitcher"
          onChange={() => setDuration(!duration)}
          size="lg"
        />
        <Text fontWeight={!duration ? 700 : 500}>Annually</Text>
      </HStack>
      <Flex className="primary-color">
        <Text className="font-weight-bold mb-7" mr="2">
          First Month FREE!
        </Text>
      </Flex>
    </Stack>
  );
}
export default DurationSwitcher;
