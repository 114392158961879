import React, { useEffect } from "react";

/** Vendor **/
import { Box, Container, Heading } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

/** Custom Components **/
import CheckoutForm from "../cards/CheckoutForm";

/** Custom CSS **/
import "../checkout.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_ID as string);

function Checkout(): React.ReactElement {
  const params = new URLSearchParams(window.location.search);
  const sub_token = params.get("sub_token") as string;

  useEffect(() => {
    if (!sub_token) {
      window.location.href = process.env.REACT_APP_DOMAIN as string;
    }
  }, [sub_token]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: sub_token,
  };

  return (
    <Box className="bg-blue-800 content-container">
      <Container className="bg-blue-800 flex flex-wrap hero-container checkout-main-container">
        <Box className="flex-center flex-right hero-left checkout-left width-50">
          <Box className="flex-column gap-spacer-6 section-content-width checkout-heading-container">
            <Heading
              as="h1"
              className="font-size-7xl font-weight-bold neutral-0"
              size={["sm", "md", "lg"]}
              style={{ maxWidth: "410px" }}
            >
              Start your journey with us
            </Heading>
            <Box
              className="font-size-2xl font-weight-normal neutral-0 checkout-sub"
              style={{ maxWidth: "485px" }}
            >
              Pursue a career that moves you
            </Box>
          </Box>
        </Box>
        <Box className="width-50 flex-center flex-start hero-right bg-blue-800">
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
        </Box>
      </Container>
    </Box>
  );
}

export default Checkout;
