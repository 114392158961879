import React from "react";

/** Custom Components**/
import { Box, Container, Heading } from "@chakra-ui/react";

function BenefitCards({ details }: any) {
  return details.map((item: any, i: number) => {
    return (
      <Box
        className="flex-center flex-column gap-spacer-6 benefit-card p-7"
        key={`benefit-card-${i}`}
      >
        {item.icon}
        <Box className="font-size-lg font-weight-semibold">{item.title}</Box>
        <Box className="font-size-md text-color-primary text-center">
          {item.sub}
        </Box>
      </Box>
    );
  });
}

function Benefits({ details, title }: any): React.ReactElement {
  return (
    <Box className="content-container">
      <Container className="flex-column flex-column-center flex-center flex-wrap section-content-padding">
        <Box className="flex-column gap-spacer-6">
          <Heading
            as="h1"
            className="font-size-5xl font-weight-bold text-color-primary benefit-section-title text-center"
            size={["sm", "md", "lg"]}
          >
            {title}
          </Heading>
        </Box>
        <Box className="benefit-container flex flex-wrap flex-column-center">
          <BenefitCards details={details} />
        </Box>
      </Container>
    </Box>
  );
}
export default Benefits;
