import React from "react";

/** Vendor **/
import { Box, Button, Link } from "@chakra-ui/react";

/** Custom Components**/
import GetStartedButton from "../../shared/navbar/cards/GetStartedButton";
import RotatingText from "./RotatingText";
import VerticalPhotos from "./VerticalPhotos";

function Hero() {
  return (
    <Box className="content-container">
      <Box className="flex home-section-height flex-wrap home-hero-container">
        <Box className="width-50 flex-center flex-right home-hero-left ">
          <Box className="flex-column gap-spacer-6 home-section-content-width home-hero-left-text-container">
            <Box className=" font-size-6xl font-weight-bold primary-color width-fit hero-heading">
              <Box className="flex">
                Traveling <RotatingText />
              </Box>
              Made Easy
            </Box>
            <Box className="font-size-2xl font-weight-normal primary-color">
              Pursue a career that moves you
            </Box>
            <Box className="flex gap-spacer-5">
              <GetStartedButton title={"Get Started For Free"} />
              <Button variant="outline" className="outline-btn-2">
                <Link href="/learn-more/traveler">Learn More</Link>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className="width-50 flex-center flex-start home-hero-right">
          <Box className="home-section-content-width height-100 home-hero-right-section">
            <VerticalPhotos />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Hero;
