import React from "react";

/** Vendor */
import { Helmet } from "react-helmet";

/** Schema for pages */
import about from "./about";
import contact from "./contact";
import facility from "./facility";
import home from "./home";
import landlord from "./landlord";
import legal from "./legal";
import traveler from "./traveler";

export default function HeaderInfo({ details = {}, page = "" }) {
  let content = ({ details = {}, page = "" }) => {
    console.log("No availble react component");
    let description = "";
    let json = {};
    let title = "";
    return { description, json, title };
  };
  let newJson = null;

  //Step 1. Determine which page
  if (page === "about") content = about;
  if (page === "contact") content = contact;
  if (page === "home") content = home;
  if (page === "learn-more-facility") content = facility;
  if (page === "learn-more-landlord") content = landlord;
  if (page === "learn-more-traveler") content = traveler;
  if (page === "legal") content = legal;

  //Step 2. Find required data to make up page header.
  const { description, json, title } = content({
    details,
    page,
  });

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={window.location.href} />
      <meta content={title} property="og:title" />
      <meta content={window.location.href} property="og:url" />
      <meta content={description} name="description" />
      <script type="application/ld+json">
        {newJson ?? JSON.stringify(json)}
      </script>
    </Helmet>
  );
}
