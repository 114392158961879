import React, { useEffect, useRef, useState } from "react";

/** Vendors */
import {
  Box,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

/** Custom Components */
import SchemaHeader from "../../shared/schema/Header";

/** Custom CSS **/
import "../legal.css";

export default function LegalTemplate() {
  const [index, setIndex] = useState(0);
  const scriptRef = useRef();

  useEffect(() => {
    (function (d, s, id) {
      const tjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      scriptRef.current = d.createElement(s);
      scriptRef.current.id = id;
      scriptRef.current.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(scriptRef.current, tjs);
      window.scrollTo(0, 0);
    })(document, "script", "termly-jssdk");
    return () => scriptRef.current.remove();
  }, [index]);

  const isMobile = window.innerWidth < 768;

  return (
    <Container className="legal" style={{ overflowY: "auto" }}>
      <SchemaHeader details={{}} page="legal" />
      <Box w="100%" p={isMobile ? 0 : 4}>
        <Tabs
          align="center"
          index={index}
          isFitted
          onChange={(index) => setIndex(index)}
          size={isMobile ? "sm" : "md"}
          variant="line"
        >
          <TabList>
            <Tab>Privacy</Tab>
            <Tab>Terms</Tab>
            <Tab>Disclaimer</Tab>
            <Tab>Acceptable Use</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <div
                className="legal-height"
                name="termly-embed"
                data-id="b10ea7f9-5b8b-40fc-951b-d7e542130778"
                data-type="iframe"
              ></div>
            </TabPanel>
            <TabPanel p={0}>
              <div
                className="legal-height"
                name="termly-embed"
                data-id="2d01f900-6202-4a07-b8c2-0d63fcf3ca60"
                data-type="iframe"
              ></div>
            </TabPanel>
            <TabPanel p={0}>
              <div
                className="legal-height"
                name="termly-embed"
                data-id="fd0ebb18-b9c8-4de3-9b36-f149c98a6cac"
                data-type="iframe"
              ></div>
            </TabPanel>
            <TabPanel p={0}>
              <div
                className="legal-height"
                name="termly-embed"
                data-id="b15cd84d-5e7f-41c0-aa78-d84450cfd881"
                data-type="iframe"
              ></div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
}
