import React, { useEffect, useState } from "react";

/** Vendors */
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

function initialiseAnalytics(): void {
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID as string;
  ReactGA.initialize(TRACKING_ID);
}

export default function usePageTracking() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initialiseAnalytics();
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
}
