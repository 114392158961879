function ChevronRight({
  color = "var(--blue800)",
  height = 48,
  width = 48,
}: {
  color?: string;
  height?: number;
  width?: number;
}): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g fill={color}>
        <path
          fill={color}
          d="M7,23.414L5.586,22l10-10l-10-10L7,0.586l10.707,10.707c0.391,0.391,0.391,1.023,0,1.414L7,23.414z"
        ></path>
      </g>
    </svg>
  );
}
export default ChevronRight;
