import React from "react";

/** Vendor **/
import { Box, Container, Heading } from "@chakra-ui/react";

function Title() {
  return (
    <Container
      className="content-padding flex-between flex-column gap-spacer-7"
      mb={10}
      mt={20}
    >
      <Heading
        as="h2"
        className="font-size-5xl font-weight-bold  text-color-primary"
        size={["sm", "md", "lg"]}
      >
        Contact Us
      </Heading>
      <Box style={{ textAlign: "center" }}>
        Let us know how we can help. We'd love to&nbsp;hear&nbsp;from&nbsp;you.
      </Box>
    </Container>
  );
}
export default Title;
