import React from "react";

/** Vendor **/
import { Box, Container, Heading } from "@chakra-ui/react";

/** Custom Components **/
import GetStartedButton from "../../shared/navbar/cards/GetStartedButton";

function TravelerCTA() {
  return (
    <Box className="bg-blue-700 content-container">
      <Container className="flex-wrap content-padding section-small">
        <Heading
          as="h1"
          className="font-size-7xl font-weight-bold neutral-0"
          size={["sm", "md", "lg"]}
        >
          Ready to Explore?
        </Heading>
        <Box className="flex-around">
          <GetStartedButton title="Get Started with a Free Account" />
        </Box>
      </Container>
    </Box>
  );
}
export default TravelerCTA;
