import React from "react";

/** Custom Components**/
import { Box, Container, Heading, Text } from "@chakra-ui/react";

const stepDetails = [
  {
    title: "Pick your Membership type",
    sub: `Register as a Traveler if you're looking for new job opportunities. Facilities and Landlords have access to our professional network`,
  },
  {
    title: "Find the right plan for you",
    sub: "Facilities and Landlords must subscribe to be able to recruit from and list jobs for our Travelers",
  },
  {
    title: "Book your next gig and start earning",
    sub: "Easily create valuable opportunities & new partnerships with Traveling Jobs",
  },
];

function LearnMoreCards({ details }: { details: any }) {
  return details.map((item: any, index: number) => {
    return (
      <Box
        className="flex-center flex-column gap-spacer-6"
        key={`steps-learn-more-${index}`}
      >
        <Box className="step-card font-size-4xl-b p-7 gap-spacer-6 ">
          <Box className="step-circle">{index + 1}</Box>
          <Box>
            <Box className="font-size-xl font-weight-semibold">
              {item.title}
            </Box>
            {item.title === "Find the right plan for you" && (
              <Box className="flex flex-column-center font-size-xl font-weight-semibold">
                (<Text className="flex text-color-primary">free&nbsp;</Text>
                for travelers)
              </Box>
            )}
          </Box>
          <Box className="font-size-md text-center text-color-gray">
            {item.sub}
          </Box>
        </Box>
      </Box>
    );
  });
}

function Steps({
  details = stepDetails,
  title = "3 Easy Steps to Get You Connected & Earning",
}: any): React.ReactElement {
  return (
    <Box className="bg-blue-700 content-container">
      <Container className="flex-between flex-column content-padding section-content-padding">
        <Box className="text-center">
          <Heading
            as="h1"
            className="font-weight-bold font-size-5xl text-color-secondary mb-6 step-title"
            size={["sm", "md", "lg"]}
          >
            {title}
          </Heading>
        </Box>
        <Box className="flex gap-spacer-8 flex-wrap flex-column-center">
          <LearnMoreCards details={details} />
        </Box>
      </Container>
    </Box>
  );
}

export default Steps;
