function ChevronLeft({
  color = "var(--blue800)",
  height = 48,
  width = 48,
}: {
  color?: string;
  height?: number;
  width?: number;
}): JSX.Element {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={color}>
        <path
          d="M17,23.414L6.293,12.707c-0.391-0.391-0.391-1.023,0-1.414L17,0.586L18.414,2l-10,10l10,10L17,23.414z"
          fill={color}
        />
      </g>
    </svg>
  );
}
export default ChevronLeft;
