import React from "react";

/** Vendor **/
import accounting from "accounting";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Input,
  useBoolean,
} from "@chakra-ui/react";

/** Custom Components **/
import Error from "../../../dist/img/svg/checkout/Error";
import Success from "../../../dist/img/svg/checkout/Success";

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [payFailure, setPayFailure] = useBoolean();

  const params = new URLSearchParams(window.location.search);
  const email = params.get("email") as string;
  const unit_amount = params.get("unit_amount") as string;
  const product_name = params.get("product_name") as string;

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_AUTH_REDIRECT_URI,
      },
      redirect: "if_required", //this prevents the redirect if it is NOT required so it'll show the modal everytime
    });

    if (result.error) {
      setPayFailure.toggle();
      onOpen();
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      setPayFailure.off();
      onOpen();
      setTimeout(() => {
        window.location.href = process.env
          .REACT_APP_AUTH_REDIRECT_URI as string;
      }, 3000);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const actions = {
    onClickCheckout: (status: boolean) => {
      if (status) {
        onClose();
        setPayFailure.toggle();
      } else {
        window.location.href = process.env
          .REACT_APP_AUTH_REDIRECT_URI as string;
      }
    },
  };

  const amount = accounting.formatMoney(
    (parseInt(unit_amount) / 100).toFixed(2)
  );

  return (
    <Box opacity={!elements ? 0 : 1} className="checkout-container">
      <Box className="checkout-header">
        <Box className="checkout-text-container">
          <Box>{product_name}</Box>
          <Box className="checkout-price">{amount}</Box>
        </Box>
      </Box>
      <Box className="checkout-form-container">
        <form className="checkout-form" onSubmit={handleSubmit}>
          <Box className="checkout-email">
            <Box className="checkout-email-label">Email</Box>
            <Input
              className="checkout-email-input"
              value={email}
              disabled
              size="md"
            />
          </Box>
          <PaymentElement />
          <Box>
            <button className="checkout-btn" disabled={!stripe}>
              Pay {amount}
            </button>
          </Box>
        </form>
      </Box>
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay className="chekcout-overlay" />
        <ModalContent style={{ padding: "24px", maxWidth: "512px" }}>
          <ModalHeader className="p-0">
            <Box className="flex-column flex-center gap-spacer-6">
              {payFailure ? (
                <Box style={{ backgroundColor: "red", borderRadius: "50%" }}>
                  <Error />
                </Box>
              ) : (
                <Success />
              )}

              <Box>{payFailure ? `Payment Failure` : `Congratulations`}</Box>
            </Box>
          </ModalHeader>
          <ModalBody className="flex-column flex-center gap-spacer-6 ">
            <Box
              className="font-size-md text-center"
              color={payFailure ? `var(--red200)` : `var(--blue800)`}
            >
              {payFailure
                ? `Payment wasn't successful. Please input your payment information again`
                : `Payment was successful! We are automatically redirecting you or
              you can press this button`}
            </Box>
            <Button
              className="checkout-pay-btn"
              onClick={() => actions.onClickCheckout(payFailure)}
            >
              {payFailure ? `Go Back` : `Continue`}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
export default CheckoutForm;
